import { Admin, AdminData, AdminDataInitialState, UpdateAdminDataAction} from "../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AdminDataInitialState = {
    value: {
        token: "",
        refreshToken: "",
        id: "",
        email: "",
        url: "http://localhost:7224"
        // url: "https://bc.cloud.torun.tech"
    },
    admins: []
};

export const adminDataSlice = createSlice({
    name: UpdateAdminDataAction,
    initialState: initialState,
    reducers: {
        setAdminData: (state, action: PayloadAction<AdminData>) => {
            state.value = action.payload; 
            state.value.url = "http://localhost:7224";
            // state.value.url = "https://bc.cloud.torun.tech";
        },
        setAllAdmins: (state, action: PayloadAction<Admin[]>) => {
            state.admins = action.payload;
        },
        deleteAdmin: (state, action: PayloadAction<string>) => {
            state.admins = state.admins.filter(item => item.id !== action.payload);
        }
    }
});

export const { setAdminData, setAllAdmins, deleteAdmin } = adminDataSlice.actions;

export default adminDataSlice.reducer;