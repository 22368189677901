import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { AdminData, SpiriumUser } from '../../../../store/types';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import DeleteDialog from './DeleteDialog';
import EditDialog from './EditDialog';
import SpiriumUserFormDialog from './UserDialogForm';
import { getValueOf } from '../../../../helpers/propValues';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from "axios";
import { setAllSpiriumUsers } from '../../../../store/slices/spiriumUsersSlice';
import { Grid } from '@mui/material';

function Row(props: { row: SpiriumUser }) {
    const { row } = props;
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.surname}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="right">
                    <EditDialog spiriumUser={row} />
                    <DeleteDialog id={row.id} name={row.name} surname={row.surname} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function UsersCollapsibleTable() {
    const spiriumUsers: SpiriumUser[] = useSelector(
        (state: RootState) => state.spiriumUsers.value
    );
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );
    const dispatch = useDispatch();
    const [rows, setRows] = React.useState<SpiriumUser[]>(spiriumUsers);
    const [searched, setSearched] = React.useState<string>("");
    const [searchFilter, setSearchFilter] = React.useState<string>("");
    const [searchBy, setSearchBy] = React.useState('');
    const [searchInput, setSearchInput] = React.useState<string>("");
    const table: string[] = ["Imię", "Nazwisko", "E-mail"];
    const tableToSeachObjects: string[] = ["name", "surname", "email"];

    const handleChange = (event: SelectChangeEvent) => {
        setSearchBy(event.target.value);
        if (event.target.value !== "") {
            setSearchFilter(tableToSeachObjects[parseInt(event.target.value)])
        } else {
            cancelSearch();
        }
    };

    React.useEffect(() => {
        setRows(spiriumUsers);
    }, [spiriumUsers])

    React.useEffect(() => {
        axios({
            method: 'post',
            url: `${user.url}/users/getAllUsers`,
            headers: { Authorization: `Bearer ${user.token}` },
            timeout: 8000
        })
            .then(response => {
                if(response.status === 200){
                    dispatch(setAllSpiriumUsers(response.data));
                }
             
            })
            .catch(error => { console.log(error) });
    }, [])

    const requestSearch = (searchedVal: string) => {
        const filteredRows = spiriumUsers.filter((row) => {
            const filter = getValueOf(row, searchFilter);
            if (filter) {
                return filter.toLowerCase().includes(searchedVal.toLowerCase());
            }
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        setSearchInput("");
        requestSearch(searched);
    };

    return (
        <>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    height: 700,
                }}
            >
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <div>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">Filtruj</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={searchBy}
                                    onChange={handleChange}
                                    label="search"
                                >
                                    <MenuItem value="">
                                        <em>Brak</em>
                                    </MenuItem>
                                    {table.map((row: string, index: any) => {
                                        return <MenuItem key={index} value={index}>{row}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <TextField id="input-with-sx" label="Szukaj" variant="standard"
                            value={searchInput}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                requestSearch(event.target.value);
                                setSearchInput(event.target.value);
                            }}>
                        </TextField>
                    </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <SpiriumUserFormDialog />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Imię</TableCell>
                                <TableCell align="left">Nazwisko</TableCell>
                                <TableCell align="left">E-mail</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <Row key={index} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>

    );
}