import AutoEmailConfig from "./AutoEmailConfig";
import ParamsThresholds from "./GlobalParamThresholdsConfig";
import React, { useState } from "react";
import { Paper, IconButton, Collapse, Box, Typography, Divider, } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const Settings = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  // const paperHeight = isCollapsed ? 70 : 335;

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        height: "auto",
        transition: "height 0.3s ease",
        overflow: "hidden",
      }}
    >
      
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography>Ustawienia globalnych progów alertów</Typography>
      </Box>
      <ParamsThresholds/>

      <Divider flexItem sx={{mb:2}}/>

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography>Ustawienia powiadomień poczty elektronicznej</Typography>
      </Box>
        <AutoEmailConfig />
    </Paper>
  );
};

export default Settings;
