import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import { AdminData } from '../../../../store/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import axios from 'axios';
import { Box } from '@mui/material';

export default function AdminFormDialog() {
    const adminInitialState = {
        email: ''
    }

    const [open, setOpen] = React.useState(false);
    const [newAdmin, setNewAdmin] = React.useState(adminInitialState);
    const [alert, setAlert] = React.useState<any>('');
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewAdmin(adminInitialState);
    };

    const sendConfirmationEmail = () => {
        const validateEmailRegex = /^\S+@\S+\.\S+$/;
        if (validateEmailRegex.test(newAdmin.email)) {
            axios({
                method: 'post',
                url: `${user.url}/admin/initAdmin?` + new URLSearchParams({ email: newAdmin.email }).toString(),
                timeout: 5000
            })
                .then(response => {
                    if(response.status === 200){
                        setOpen(false);
                    }
                    console.log(response);
                })
                .catch(error => { console.log(error) });
            handleClose();
            setAlert('');
        } else {
            setAlert(<Alert severity="error">Wpisany adres e-mail jet niepoprawny!</Alert>)
        }
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: "flex-end" }}>
            <Tooltip title="Dodaj administratora" arrow placement='left'>
                <IconButton sx={{ mt: 2, mb: 2 }} onClick={handleClickOpen}>
                    <PersonAddAlt1Icon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
                <DialogTitle>Nowy administrator</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="E-mail"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newAdmin.email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewAdmin({ ...newAdmin, email: event.target.value });
                            setAlert('');
                        }}
                    />
                    {alert}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        sendConfirmationEmail();
                    }}>Dodaj</Button>
                    <Button onClick={handleClose}>Anuluj</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}