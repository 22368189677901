import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LogoImg from '../../assets/logo-tt.png'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AdminData } from '../../store/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://torun.tech/">
                TORUN Technologies
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function PasswordRecovery() {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    const keyParam = urlParams.get('token');
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setOpen2(false);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        axios({
            method: 'post',
            url: `${user.url}/admin/generateNewPassword`,
            data: {
                email: emailParam,
                password: data.get('password'),
                confirmPassword: data.get('repeatPass'),
                resetPasswordToken: keyParam
            },
            timeout: 8000
        })
            .then(response => {
                if (response.status === 200) {
                    setOpen(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 5000)
                } else {
                    setOpen2(true);
                }
            })
            .catch(error => { console.log(error); setOpen2(true) });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <img src={LogoImg} alt="TT Logo" style={{ width: '80%' }} />
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Zmiana hasła
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Adres e-mail"
                                name="email"
                                autoComplete="email"
                                disabled
                                defaultValue={emailParam}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Nowe hasło"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="repeatPass"
                                label="Powtórz hasło"
                                type="password"
                                id="repeatPass"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Zatwierdź
                            </Button>
                            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                    Hasło zostało zmienione! <br></br> Za chwilę zostaniesz przeniesiony do widoku logowania.
                                </Alert>
                            </Snackbar>
                            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open2} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                    Wystąpił błąd!
                                </Alert>
                            </Snackbar>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}