import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Stack,
  Box,
  IconButton,
  Tooltip,
  Snackbar,
  SnackbarContent,
  Grid,
} from "@mui/material";
import {
  InfoOutlined,
  AddLinkOutlined,
  CheckOutlined,
  Edit,
  ClearOutlined,
  LockResetOutlined,
  AccountCircleOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { AdminData } from "../../../../store/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const EmailBox = (props: { msgType: string }) => {
  const [message, setMessage] = useState("Wiadomość"); //pobierana z koncowki bedzie
  const [isToastOpen, setToastOpen] = useState(false);
  const [isErrorToastOpen, setErrorToastOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const inputRef = useRef(null) as any;
  const isLinkPlaceholderPresent = message.includes("{{ link }}");

  const user: AdminData = useSelector(
    (state: RootState) => state.adminData.value
  );

  const getMsg = () => {
    axios({
      method: "get",
      url: `${user.url}/admin/getEmailMessage?msgType=${props.msgType}`,
      timeout: 8000,
    })
      .then((response) => {
        setMessage(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getMsg();
  }, []);

  const handleInputChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handlePasteDefaultLink = () => {
    const linkPlaceholder = "{{ link }}";
    const input = inputRef.current;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    setMessage(message.slice(0, start) + linkPlaceholder + message.slice(end));
    input.focus();
    input.selectionStart = input.selectionEnd = start + linkPlaceholder.length;
  };

  const sendMessage = () => {
    //console.log(message);

    axios({
      method: "post",
      url: `${user.url}/admin/setEmailMessage?message=${message}&msgType=${props.msgType}`,
      timeout: 8000,
    })
      .then((response) => {
        console.log("STATUS", response.status);
        if (response.status === 200) {
          setToastOpen(true);
          setEditMode(false);
          getMsg();
        } else {
          setErrorToastOpen(true);
          setEditMode(false);
          getMsg();
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorToastOpen(true);
      });
  };

  return (
    <Box
      sx={{
        p: 1,
        mt: 2,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {props.msgType === "newUser" ? (
          <>
            <AccountCircleOutlined color="primary" />
            <Typography>Nowy użytkownik</Typography>
          </>
        ) : (
          <>
            <LockResetOutlined color="primary" />
            <Typography>Reset hasła</Typography>
          </>
        )}
      </Stack>
      <TextField
        label="Treść wiadomości"
        multiline
        rows={3}
        value={message}
        onChange={handleInputChange}
        variant="outlined"
        style={{ width: "100%", marginTop: 20 }}
        inputRef={inputRef}
        disabled={!isEditMode}
      />
      <Box display="flex">
        {isEditMode ? (
          <>
            <Tooltip title="Wklej link">
              <span>
                <IconButton
                  color="primary"
                  onClick={handlePasteDefaultLink}
                  disabled={isLinkPlaceholderPresent}
                >
                  <AddLinkOutlined />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Anuluj">
              <IconButton
                color="primary"
                onClick={() => {
                  getMsg();
                  setEditMode(false);
                  // setMessage("Defaultowa wiadomość");
                }}
              >
                <ClearOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Zatwierdź">
              <IconButton
                color="primary"
                onClick={sendMessage}
                disabled={!isLinkPlaceholderPresent}
              >
                <CheckOutlined />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Edytuj">
            <IconButton
              color="primary"
              onClick={() => {
                setEditMode(true);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={3000}
        onClose={() => {
          setToastOpen(false);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SnackbarContent
          message="Pomyślnie zapisano wiadomość!"
          style={{ backgroundColor: "green" }}
        />
      </Snackbar>
      <Snackbar
        open={isErrorToastOpen}
        autoHideDuration={3000}
        onClose={() => {
          setErrorToastOpen(false);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SnackbarContent
          message="Wystąpił błąd podczas zapisywania wiadomości."
          style={{ backgroundColor: "red" }}
        />
      </Snackbar>
    </Box>
  );
};

const AutoEmailConfig = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <InfoOutlined sx={{ color: "grey", ml: 1 }} />
            <Typography>
              Poniższe treści zostaną wyświetlone w automatycznej wiadomości
              e-mail jako informacja dotycząca tworzenia nowego konta lub resetu
              hasła w systemie OxyVent.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <EmailBox msgType="newUser" />
        </Grid>
        <Grid item xs={6}>
          <EmailBox msgType="resetPass" />
        </Grid>
      </Grid>
    </>
  );
};

export default AutoEmailConfig;
