import { configureStore } from "@reduxjs/toolkit";
import spiriumUsersReducer from "./slices/spiriumUsersSlice";
import spiriumPatientsReducer from "./slices/spiriumPatientsSlice";
import adminReducer from "./slices/adminDataSlice";
import devicesReducer from "./slices/devicesDataSlice";

const store = configureStore({
  reducer: {
    spiriumUsers: spiriumUsersReducer,
    spiriumPatients: spiriumPatientsReducer,
    adminData: adminReducer,
    devices: devicesReducer
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;