import React, { useState, useRef, useEffect } from "react";
import {
    TextField,
    Button,
    Typography,
    Stack,
    Box,
    IconButton,
    Tooltip,
    Snackbar,
    SnackbarContent,
    Grid,
    Container,
    Input,
} from "@mui/material";
import {
    InfoOutlined,
    AddLinkOutlined,
    CheckOutlined,
    Edit,
    ClearOutlined,
    LockResetOutlined,
    AccountCircleOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { AdminData, AlertThreshold } from "../../../../store/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const ParamsThresholds = () => {
    const [isEditMode, setEditMode] = useState(false);
    const [complianceValue, setComplianceValue] = useState<AlertThreshold>({ } as AlertThreshold);
    const [ahiValue, setAhiValue] = useState<AlertThreshold>({ } as AlertThreshold);
    const [leakageValue, setLeakageValue] = useState<AlertThreshold>({ } as AlertThreshold);
    const [saturationValue, setSaturationValue] = useState<AlertThreshold>({ } as AlertThreshold);
    const inputRef = useRef(null) as any;

    const user: AdminData = useSelector((state: RootState) => state.adminData.value);

    const getThresholds = () => {
        axios({
            method: "get",
            url: `${user.url}/admin/getGlobalAlertsThresholds`,
            timeout: 15000,
        })
            .then((response) => {
              setComplianceValue(response.data.find((x:AlertThreshold) => x.paramName == 'compliance'));
              setLeakageValue(response.data.find((x:AlertThreshold) => x.paramName == 'leakage'));
              setAhiValue(response.data.find((x:AlertThreshold) => x.paramName == 'ahi'));
              setSaturationValue(response.data.find((x:AlertThreshold) => x.paramName == 'saturation'));
              // wrzucic dane w zmienna reaktywna
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getThresholds();
    }, []);

    const handleComplianceChange = (event: any) => {
      setComplianceValue(prevState => ({...prevState, setVal:event.target.value}));
    };
    const handleLeakageChange = (event: any) => {
        setLeakageValue(prevState => ({...prevState, setVal:event.target.value}));
    };
    const handleAhiChange = (event: any) => {
        setAhiValue(prevState => ({...prevState, setVal:event.target.value}));
    };
    const handleSaturationChange = (event: any) => {
        setSaturationValue(prevState => ({...prevState, setVal:event.target.value}));
    };

    const setThresholds = () => {
        axios({
            method: "post",
            url: `${user.url}/admin/setGlobalAlertsThresholds`,
            data: [
              { paramName: 'compliance', setVal: complianceValue.setVal },
              { paramName: 'leakage', setVal: leakageValue.setVal },
              { paramName: 'ahi', setVal: ahiValue.setVal },
              { paramName: 'saturation', setVal: saturationValue.setVal },
            ],
            timeout: 15000,
        })
            .then((response) => {
                console.log("STATUS", response.status);
                if (response.status === 200) {
                    setEditMode(false);
                } else {
                    setEditMode(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (

        <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" sx={{p:1, mb:2}}>

              <Stack direction="row" useFlexGap spacing={{xs:1, sm:2 }}>
                <Typography>Compliance [godziny]: </Typography>
                <Input disabled={!isEditMode}
                  value={complianceValue.setVal}
                  size="small"
                  onChange={handleComplianceChange}
                  inputProps={{
                    step: .5,
                    min: {...complianceValue.minVal},
                    max: {...complianceValue.maxVal},
                    type: 'number',
                  }}
                />
              </Stack>
              <Stack direction="row" useFlexGap spacing={{xs:1, sm:2 }}>
                <Typography>Przeciek [l/min]: </Typography>
                <Input disabled={!isEditMode}
                  value={leakageValue.setVal}
                  size="small"
                  onChange={handleLeakageChange}
                  inputProps={{
                    step: 1,
                    min: {...leakageValue.minVal},
                    max: {...leakageValue.maxVal},
                    type: 'number',
                  }}
                />
              </Stack>
              <Stack direction="row" useFlexGap spacing={{xs:1, sm:2 }}>
                <Typography>AHI: </Typography>
                <Input disabled={!isEditMode}
                  value={ahiValue.setVal}
                  size="small"
                  onChange={handleAhiChange}
                  inputProps={{
                    step: 1,
                    min: {...ahiValue.minVal},
                    max: {...ahiValue.maxVal},
                    type: 'number',
                  }}
                />
              </Stack>
              <Stack direction="row" useFlexGap spacing={{xs:1, sm:2 }}>
                <Typography>Saturacja [%]: </Typography>
                <Input disabled={!isEditMode}
                  value={saturationValue.setVal}
                  size="small"
                  onChange={handleSaturationChange}
                  inputProps={{
                    step: 1,
                    min: {...saturationValue.minVal},
                    max: {...saturationValue.maxVal},
                    type: 'number',
                  }}
                />
              </Stack>
                  <Stack direction="row">

                  <Button onClick={()=>setEditMode(!isEditMode)}>
                    { isEditMode ? "Anuluj" : "Edytuj" } 
                  </Button>
                  <Button disabled={!isEditMode} onClick={setThresholds}>Zapisz</Button>
                  </Stack>
        </Stack>

    );
};

export default ParamsThresholds;