import { SpiriumPatient, SpiriumPatientsInitialState, UpdateSpiriumPatientsAction } from "../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SpiriumPatientsInitialState = {
    value: []
};

export const spiriumPatientsSlice = createSlice({
    name: UpdateSpiriumPatientsAction,
    initialState: initialState,
    reducers: {
        addSpiriumPatient: (state, action: PayloadAction<SpiriumPatient>) => {
            state.value.push(action.payload)
        },
        deleteSpiriumPatient: (state, action: PayloadAction<string>) => {
            state.value = state.value.filter(item => item.id !== action.payload);
        },
        editSpiriumPatient: (state, action: PayloadAction<SpiriumPatient>) => {
            let objIndex = state.value.findIndex((obj => obj.id === action.payload.id));
            state.value[objIndex] = { ...action.payload }
        },
        setAllSpiriumPatients: (state, action: PayloadAction<SpiriumPatient[]>) => {
            state.value = action.payload;
        }
    },
});

export const { addSpiriumPatient, deleteSpiriumPatient, editSpiriumPatient, setAllSpiriumPatients } = spiriumPatientsSlice.actions;

export default spiriumPatientsSlice.reducer;