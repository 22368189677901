import React, { useEffect, useState } from "react";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { Paper, IconButton, Collapse, Box, Typography, List, ListItem, Select, MenuItem, InputLabel, SelectChangeEvent, 
  FormControl, Grid, Container, Stack, ListItemButton, ListItemIcon, Checkbox, ListItemText, 
  Button} from "@mui/material";
import { ExpandMore, ExpandLess, CheckBox, Info, InfoOutlined } from "@mui/icons-material";
import { AdminData, SpiriumPatient, SpiriumUser } from "../../../../store/types";
import axios from "axios";
import { setAllSpiriumPatients } from "../../../../store/slices/spiriumPatientsSlice";


export default function Assignings ()  {

  const user: AdminData = useSelector(
    (state: RootState) => state.adminData.value
  );
  const spiriumUsers: SpiriumUser[] = useSelector(
      (state: RootState) => state.spiriumUsers.value
  );
  const allPatients: SpiriumPatient[] = useSelector(
    (state: RootState) => state.spiriumPatients.value
  );

  const [selectedUser, setSelectedUser] = React.useState<string>("");
  const [patients, setPatients] = React.useState<SpiriumPatient[]>([]);
  const [selectedUsersPatients, setSelectedUsersPatients] = React.useState<string[]>([]);

  useEffect(() => {
    axios({
      method: "post",
      url: `${user.url}/patients/getAllPatients`,
      timeout: 12000,
    })
      .then((response) => {
        console.log("/patients/getAllPatients response:", response);
        if (response.status === 200) {
          setPatients(response.data);
          // setAllSpiriumPatients(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])

  const handleUserSelection = async (event: SelectChangeEvent) => {
    setSelectedUser(event.target.value);
    await axios({
      method: "post",
      url: `${user.url}/admin/getAllUsersPatients?userId=${event.target.value}`,
      timeout: 12000,
    })
      .then((response) => {
        console.log("/admin/getAllUsersPatients response:", response);
        if (response.status === 200) {
          setSelectedUsersPatients(response.data);
          // setAllSpiriumPatients(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePatientToggle = (patientId: string) => () => {
    console.log("check toggle", patientId);
    let i = selectedUsersPatients.findIndex(p => p == patientId);
    console.log(i);
    if(i >= 0)
      selectedUsersPatients.splice(i, 1);
    else
      selectedUsersPatients.push(patientId);
    setSelectedUsersPatients([...selectedUsersPatients]);
    console.log(selectedUsersPatients);
  }

  const saveUsersPatients = () => {
    axios({
      method: "post",
      url: `${user.url}/admin/setUsersPatients?userId=${selectedUser}`,
      data: selectedUsersPatients,
      timeout: 12000,
    })
      .then((response) => {
        console.log("/admin/setUsersPatients response:", response);
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        height: "auto",
        transition: "height 0.3s ease",
        overflow: "hidden",
      }}
    >
      <Grid container columns={2} gap={4}>
        
        <Stack >
          <Typography>Lista użytkowników:</Typography>
          
          <FormControl sx={{ m:1, minWidth: 150}}>
            <InputLabel id="select-user-label">Użytkownik</InputLabel>
            <Select value={selectedUser} label="Użytkownik" labelId="select-user-label" onChange={handleUserSelection}>
                { spiriumUsers.map(su => {
                  return <MenuItem value={su.id} key={su.id}>{su.name} {su.surname}</MenuItem>
                })}
            </Select>
            {selectedUser}
            
          </FormControl>
          <Button onClick={saveUsersPatients}>Zapisz</Button>
        </Stack>
        
        <Box>
          Pacjenci:
          <List>
            
            {patients.map((data, index) => (
              <ListItem key={data.id} secondaryAction={
                <IconButton edge="end">
                  <InfoOutlined/>
                </IconButton>
              } disablePadding>
                <ListItemButton disableRipple>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedUsersPatients.includes(data.id)}
                      onChange={handlePatientToggle(data.id)}
                      
                    />
                    <ListItemText>
                      {data.name + ' ' + data.surname}
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

        </Box>

      </Grid>

    </Paper>
  );
};
