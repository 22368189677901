import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//ikony
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useNavigate } from 'react-router-dom';

export const MainListItems = () => {
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const navigate = useNavigate();

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
      ) => {
        setSelectedIndex(index);
      };

    const toAdmins = () => {
        navigate('/home/admins');
    }
    const toUsers = () => {
        navigate('/home/users');
    }
    const toDevices = () => {
        navigate('/home/devices');
    }
    const toSettings = () => {
        navigate('/home/settings');
    }
    const toAssignings = () => {
        navigate('/home/assignings');
    }

    return (<React.Fragment>
        <ListItemButton selected={selectedIndex === 0} onClick={(event) => { handleListItemClick(event, 0); toAdmins()} }>
            <ListItemIcon>
                <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Administratorzy" />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 1} onClick={(event) => { handleListItemClick(event, 1); toUsers()} }>
            <ListItemIcon>
                <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="Użytkownicy" />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 2} onClick={(event) => { handleListItemClick(event, 2); toDevices()} }>
            <ListItemIcon>
                <AnalyticsIcon />
            </ListItemIcon>
            <ListItemText primary="Urządzenia" />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 3} onClick={(event) => { handleListItemClick(event, 3); toSettings()} }>
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Ustawienia" />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 4} onClick={(event) => { handleListItemClick(event, 4); toAssignings()} }>
            <ListItemIcon>
                <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="Przypisania" />
        </ListItemButton>
    </React.Fragment>)
}


