import { SpiriumUser, SpiriumUsersInitialState, UpdateSpiriumUsersAction } from "../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SpiriumUsersInitialState = {
    value: []
};

export const spiriumUsersSlice = createSlice({
    name: UpdateSpiriumUsersAction,
    initialState: initialState,
    reducers: {
        addSpiriumUser: (state, action: PayloadAction<SpiriumUser>) => {
            state.value.push(action.payload)
        },
        deleteSpiriumUser: (state, action: PayloadAction<string>) => {
            state.value = state.value.filter(item => item.id !== action.payload);
        },
        editSpiriumUser: (state, action: PayloadAction<SpiriumUser>) => {
            let objIndex = state.value.findIndex((obj => obj.id === action.payload.id));
            state.value[objIndex] = { ...action.payload }
        },
        setAllSpiriumUsers: (state, action: PayloadAction<SpiriumUser[]>) => {
            state.value = action.payload;
        }
    },
});

export const { addSpiriumUser, deleteSpiriumUser, editSpiriumUser, setAllSpiriumUsers } = spiriumUsersSlice.actions;

export default spiriumUsersSlice.reducer;