import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LogoImg from '../../assets/logo-tt.png'
import AdminRecoveryFormDialog from './RecoveryDialog';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAdminData } from '../../store/slices/adminDataSlice';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AdminData } from '../../store/types';
import { RootState } from '../../store/store';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://torun.tech/">
                TORUN Technologies
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function LogIn() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [btnTrigger, setBtnTrigger] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );

    const cancelButton = () => {
        setBtnTrigger(false);
    }

    const activateButton = () => {
        setBtnTrigger(true);
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        activateButton();

        axios({
            method: 'post',
            url: `${user.url}/auth/adminLogin`,
            data: { email: form.get('email'), password: form.get('password') },
            timeout: 8000
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch(setAdminData({
                        token: response?.headers?.["x-access-token"],
                        refreshToken: response?.headers?.["refresh-token"],
                        id: response.data,
                        email: form.get('email') as string
                    }))
                    cancelButton();
                    navigate('/home/users');
                }
            })
            .catch(error => { console.log(error); setOpen(true); cancelButton(); });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <img src={LogoImg} alt="TT Logo" style={{ width: '70%' }} />
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Logowanie
                        </Typography>
                        <Typography component="h2" variant="h5">
                            Panel administratora
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Adres e-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Hasło"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ mt: 3, mb: 2 }}
                                disabled={btnTrigger}
                                type="submit"
                            >
                                Zaloguj
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <AdminRecoveryFormDialog />
                                </Grid>
                            </Grid>
                            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                    Logowanie nie powiodło się!
                                </Alert>
                            </Snackbar>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}