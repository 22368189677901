import UsersCollapsibleTable from "./Dashboard/Datatable/Users/UsersCollapsibleTable";
import AdminsCollapsibleTable from "./Dashboard/Datatable/Admins/AdminsCollapsibleTable";
import DevicesTable from "./Dashboard/Datatable/Devices/DevicesTable";
import Settings from "./Dashboard/Datatable/Settings/Settings";
import Assignings from "./Dashboard/Datatable/Assignings/Assignings";

export function UsersTab() { return (<><UsersCollapsibleTable /></>) }
export function AdminsTab() { return (<><AdminsCollapsibleTable /></>) }
export function DeviceTab() { return (<><DevicesTable /></>) }
export function SettingsTab() { return (<Settings/>) }
export function AssigningsTab() { return (<Assignings/>) }
export function NoneTab() { return (<><h1>Tutaj trwają prace!!!</h1></>) }